html {
  font-family: 'DM Sans';
  font-size: 14px;
}

.ant-message-notice-content {
  background: #393e50;
  color: #ebeced;
  font-weight: 600;
  font-size: 1.14rem;
  border-radius: 8px;
}

.ant-message-notice-content .ant-message-info .anticon {
  color: #ebeced;
}

/* .ant-switch {
  background-color: #e0e9ed;
}

.ant-switch-checked {
  background-color: #ff9150;
}

.ant-switch-small.ant-switch-checked {
  background-color: #393e50;
} */

.ant-table-filter-dropdown {
  border-radius: 3px;
}

.ant-popover-inner-content {
  padding: 8px 0;
}
.ant-popover-inner {
  border-radius: 12px;
  box-shadow: '0px 2px 16px -4px rgba(23, 30, 41, 0.08)';
  /* box-shadow: none; */
  /* border: 1px solid #edf4f6; */
}

.ant-popover-arrow {
  display: none;
}

.ant-spin {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.body-12 {
  font-size: 0.75rem;
  line-height: 0.937rem;
  font-weight: 400;
}

p,
.body-14 {
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 400;
}
.body-16 {
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;
}
.body-18 {
  font-size: 1.125rem;
  line-height: 1.6;
  font-weight: 400;
}
.body-36 {
  font-size: 2.25rem;
  line-height: 2, 9375rem;
  font-weight: 700;
}
.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
.underline {
  text-decoration: underline;
}
.line-through {
  text-decoration: line-through;
}
.capitalized {
  text-transform: capitalize;
}

.small {
  font-size: 12px;
  line-height: 18px;
}

.pointer {
  cursor: pointer;
}

.slashed {
  text-decoration-line: line-through;
}

/* color class */
.mainColor {
  color: #d31f26;
}
.dark0 {
  color: #111122;
}
.dark1 {
  color: #424259;
}
.dark2 {
  color: #878899;
}
.dark3 {
  color: #b3b4c1;
}
.dark4 {
  color: #c7c9d9;
}
.green0 {
  color: #05a660;
}
.green1 {
  color: #06c270;
}
.red0 {
  color: #e53535;
}
.red1 {
  color: #ff3b3b;
}
.red2 {
  color: #ff5c5c;
}
.orange {
  color: #ff8800;
}
.blue1 {
  color: #0063f7;
}
.light1 {
  color: #ebebf0;
}
.light2 {
  color: #f2f2f5;
}
.light3 {
  color: #f8f8fa;
}
.light4 {
  color: #ffffff;
}
.black {
  color: #000000;
}
.naturalDarker {
  color: #383838;
}
